'use client';

import ClientOnly from '@/components/ClientOnly';
import DiscordAuthButton from '@/components/DiscordAuthButton';
import LaunchpadLogo from '@/components/LaunchpadLogo';
import WalletMultiButton from '@/components/WalletMultiButton';

export default function TopBar({ className }) {
  return (
    <div
      className={`flex items-center justify-center flex-col w-full drop-shadow-topbar z-20 ${
        className || ''
      }`}
    >
      <div className="flex py-4 px-2 md:px-8 w-full items-center justify-between max-w-screen-xl">
        <LaunchpadLogo />
        <div className="flex flex-col-reverse items-center md:flex-row md:justify-end min-w-[175px] md:space-x-4">
          <ClientOnly>
            <WalletMultiButton
              style={{
                backgroundColor: '#9af08f',
                '&:hover': {
                  backgroundColor: '#7dd072',
                },
                color: '#000000',
              }}
            />
            <DiscordAuthButton />
          </ClientOnly>
        </div>
      </div>
      <div className="w-full border-b border-semitrans" />
    </div>
  );
}
