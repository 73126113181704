import { isString } from 'lodash-es';
import { format } from 'date-fns';
import { LAMPORTS_PER_SOL, Connection, Keypair, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { getRandomPrivateNode } from '@/lib/config';
import { MEMO_PROGRAM_PKEY } from '@/lib/constants';
import { images as imgOptimizeConfig } from '@/next.config';

export function getPubkeyShorthand(rawPublicKey) {
  let publicKey = rawPublicKey;
  if (isString(rawPublicKey)) {
    publicKey = new PublicKey(rawPublicKey);
  }
  if (!(publicKey && publicKey.toBase58)) {
    return '';
  }
  const s = publicKey.toBase58();
  return [s.slice(0, 4), s.slice(-4)];
}

/**
 * For operations that happen outside of the react tree, manually
 * derive a Connection based on our currently-configured network */
export function getConnection() {
  const rpcNode = getRandomPrivateNode();
  return new Connection(rpcNode, 'finalized');
}

export async function fetchOwnedNFTs(publicKey, conn) {
  try {
    const connection = conn || getConnection();
    const response = await connection.getParsedTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID,
    });
    const mints = await Promise.all(
      response.value
        .filter(
          (accInfo) =>
            accInfo.account.data.parsed.info.tokenAmount.uiAmount == 1 &&
            accInfo.account.data.parsed.info.tokenAmount.decimals == 0
        )
        .map((accInfo) => accInfo.account.data.parsed.info.mint)
    );
    return mints;
  } catch (err) {
    console.warn('Failed to fetch NFTs for', publicKey?.toBase58(), err);
    return [];
  }
}

/**
 * Some components, like the wallet adapters, will break the build if
 * you attempt to render them server-side.
 */
export function isClientSide() {
  return typeof window !== 'undefined';
}

export function solToLamports(amt) {
  return parseInt(Math.ceil(amt * LAMPORTS_PER_SOL), 10);
}

export function tokenToLamports(decimals, amt) {
  return parseInt(Math.ceil(amt * Math.pow(10, decimals || 9)), 10);
}

export function lamportsToSol(lamports) {
  return Number((lamports / LAMPORTS_PER_SOL).toFixed(3));
}

export function lamportsToToken(decimals, lamports) {
  return Number((lamports / Math.pow(10, decimals || 9)).toFixed(3));
}

// Expect d to be a Date object
export function formatDate(d, f = 'PPpp') {
  return format(d, f);
}

export function getTransactionKeys(tx) {
  const keys = new Set();
  tx.instructions.forEach((i) => {
    i.keys
      .map(({ pubkey }) => pubkey?.toBase58())
      .forEach((pk) => {
        keys.add(pk);
      });
  });
  return Array.from(keys);
}

export function getTransactionMemos(tx) {
  const memos = tx?.instructions?.filter((i) => i.programId.toBase58() === MEMO_PROGRAM_PKEY);
  return memos.map((i) => i.data).map((b) => b.toString('utf-8'));
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function loadPrivateKey(envvar) {
  return Keypair.fromSecretKey(Buffer.from(JSON.parse(envvar)));
}

export function canOptimizeImage(imageUrl) {
  if (!imageUrl || imageUrl.length < 2) {
    return false;
  }
  if (imageUrl[0] === '/' && imageUrl[1] !== '/') {
    return true;
  }
  const { domains } = imgOptimizeConfig;
  return domains?.some((d) => {
    const [, , domain] = imageUrl.split('/');
    return domain?.includes(d);
  });
}

export function copyToClipboard(contents) {
  const el = document.createElement('textarea');
  el.value = contents;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function stripMarkdownLinks(text) {
  const markdownLinkAndImageRegex = /!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)/g;
  return text.replace(markdownLinkAndImageRegex, '');
}
