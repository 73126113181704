import Image from 'next/image';
import Link from 'next/link';
import { format } from 'date-fns';
import { ABSOLUTE_MIN_BID, BLANK_PNG } from '@/lib/constants';
import { canOptimizeImage, lamportsToToken } from '@/lib/utils';

import PhaseCountdown from '@/components/PhaseCountdown';

// The projectData variable is a combination of project, artwork, and artist data
// See the `getHomepageProjects` function in lib/data/projects.js
export default function ProjectCard({
  projectData,
  title,
  subTitle,
  image,
  startDate,
  projectType,
}) {
  const imgSrc = image || (projectData?.images && projectData.images[0]) || BLANK_PNG;
  const type = projectType || projectData?.artist_name ? 'Edition' : 'Collection';
  const start = new Date(startDate || projectData?.start_time);
  const displayTitle =
    title || projectData.title || projectData?.artwork_title || 'Unknown Offering';
  const { token, decimals } = projectData || {};
  return (
    <Link
      href={`/projects/${projectData.project_id}`}
      className="flex flex-col md:flex-row items-center border border-borderBlue p-4 md:p-8 w-full rounded md:space-x-12 h:auto md:h-72"
    >
      <div className="h-56 w-96 bg-base-200">
        <Image
          className="object-cover rounded-xl w-full h-full"
          height={200}
          width={300}
          src={imgSrc}
          alt={`${displayTitle} image`}
          unoptimized={!canOptimizeImage(imgSrc)}
        />
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col space-y-2">
          <h2 className="text-h1 font-semibold">{displayTitle}</h2>
          <h3 className="text-h2">
            {subTitle ||
              (projectData?.artist_name ? `A ${projectData.artist_name} edition drop` : '')}
          </h3>
          {start && <p className="font-light">{format(start, 'PPPPpp')}</p>}
        </div>
        <div className="flex-grow h-full min-h-8" />
        <div className="flex flex-col">
          {/* Badges */}
          <div className="hidden badge-accent badge-primary" />
          <div className="flex items-center flex-wrap">
            <div className="badge badge-lg badge-accent my-1 mr-2 md:my-0">{type}</div>
            {projectData?.is_active && (
              <div className="badge badge-lg badge-primary my-1 mr-2 md:my-0">LIVE!</div>
            )}
            {projectData?.start_time && projectData?.end_time && (
              <div className="badge badge-lg badge-outline my-1 mr-2 md:my-0">
                <PhaseCountdown
                  bordered={false}
                  phase={{
                    start_time: projectData.start_time,
                    end_time: projectData.end_time,
                    name: projectData.phase_id === 1 ? 'Public Phase' : 'WL',
                  }}
                />
              </div>
            )}
          </div>

          {/* Price / Supply */}
          <div className="flex items-center px-1 mt-2 space-x-8">
            <div className="flex items-center space-x-2">
              <p>Minimum Bid: </p>
              <div className="flex items-center space-x-1">
                <p className="font-mono">
                  {lamportsToToken(decimals || 9, projectData?.min_bid || ABSOLUTE_MIN_BID)}
                </p>
                {token === 'SOL' ? (
                  <Image src="/solana-logo.svg" alt="SOL logo" height={14} width={15} />
                ) : (
                  <span className="pl-1">{token}</span>
                )}
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <p>Total Supply:</p>
              <p className="font-mono">{projectData?.supply}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
