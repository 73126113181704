/** @type {import('next').NextConfig} */
const nextConfig = {
  productionBrowserSourceMaps: true, // TODO: remove me
  eslint: {
    dirs: ['app', 'components', 'lib', 'pages'],
  },
  images: {
    domains: [
      'arweave.net',
      '*.arweave.net',
      'images-cdn.exchange.art',
      'pbs.twimg.com',
      'cdn.discordapp.com',
    ],
  },
  reactStrictMode: true,
  async redirects() {
    return [
      {
        source: '/a',
        destination: '/',
        permanent: false,
      },
      {
        source: '/pitch',
        destination:
          'https://www.canva.com/design/DAFY9UdNIOA/CGKLUuz1COUUhiIsQcPBkg/view?utm_content=DAFY9UdNIOA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
        permanent: true,
      },
      {
        source: '/deck',
        destination:
          'https://www.canva.com/design/DAFY9UdNIOA/CGKLUuz1COUUhiIsQcPBkg/view?utm_content=DAFY9UdNIOA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
        permanent: true,
      },
      {
        source: '/pitch-deck',
        destination:
          'https://www.canva.com/design/DAFY9UdNIOA/CGKLUuz1COUUhiIsQcPBkg/view?utm_content=DAFY9UdNIOA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
        permanent: true,
      },
    ];
  },
  webpack: (config) => {
    const oldFallback = config.resolve.fallback;
    config.resolve.fallback = {
      ...oldFallback,
      'better-sqlite3': false,
      crypto: require.resolve('crypto-browserify'),
    };
    return config;
  },
};

module.exports = nextConfig;
