'use client';

import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSession, signIn, signOut } from 'next-auth/react';
import { FaDiscord } from 'react-icons/fa';

export default function DiscordAuthButton() {
  const { data: session } = useSession();

  const handleClick = () => {
    if (session) {
      signOut();
    } else {
      signIn('discord');
    }
  };

  // Automatically sign out if their access token expires
  useEffect(() => {
    if (session) {
      const currentTime = Date.now();
      const expiryTime = new Date(session.token.expires_at * 1000).getTime();
      if (currentTime >= expiryTime) {
        signOut({ redirect: false });
        // Optionally, redirect to login page or show a message
      }
      if (currentTime < expiryTime && expiryTime - currentTime < 120000) {
        toast.info(`Session expires in ${Math.floor((expiryTime - currentTime) / 1000)} seconds`);
        toast.info('Please log out and log back in to refresh your session');
      }
    }
  }, [session]);

  return (
    <button className="btn btn-accent min-w-[8rem]" onClick={handleClick}>
      {session ? (
        <div className="tooltip tooltip-bottom" data-tip="Log out">
          <div className="flex w-full justify-center items-center space-x-2">
            <FaDiscord size={18} />
            <span>{session.user.name}</span>
          </div>
        </div>
      ) : (
        <div className="flex w-full justify-center items-center space-x-2">
          <FaDiscord size={18} />
          <span>Log in</span>
        </div>
      )}
    </button>
  );
}
