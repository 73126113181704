'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { intervalToDuration } from 'date-fns';

export default function PhaseCountdown({ phase, bordered = true }) {
  const [now, setNow] = useState(new Date());
  const startTime = useMemo(() => new Date(phase?.start_time), [phase]);
  const endTime = useMemo(() => new Date(phase?.end_time), [phase]);
  const isComplete = useMemo(() => now > endTime, [now, endTime]);

  const updateTime = useCallback(() => {
    setNow(new Date());
  }, [setNow]);

  useEffect(() => {
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  }, [updateTime]);

  if (!phase) {
    return null;
  }

  const isActive = startTime.getTime() <= now.getTime() && now.getTime() < endTime.getTime();
  const text = isComplete ? 'Complete' : isActive ? 'Ends in:' : 'Starts in:';
  const { months, days, hours, minutes, seconds } = intervalToDuration({
    start: now,
    end: now < startTime ? startTime : endTime,
  });
  const allHours = `${months * 30 * 24 + days * 24 + hours}`;

  return (
    <div
      className={`flex w-full p-2 font-light justify-between ${
        bordered
          ? `rounded border border-${isActive ? 'primary' : isComplete ? 'base-200' : 'neutral'}`
          : ''
      }`}
    >
      <div className="flex space-x-2 items-center border-primary border-base-200">
        <p className={isComplete ? 'font-medium uppercase' : ''}>
          {phase.name} {text}
        </p>
        {!isComplete && (
          <div className="flex items-center">
            <span className="font-mono">
              {allHours.padStart(Math.max(allHours.length, 2), '0')}:
            </span>
            <span className="-center countdown font-mono">
              <span style={{ '--value': minutes }}></span>:
              <span style={{ '--value': seconds }}></span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
