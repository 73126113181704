'use client';

import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import BidhubFooter from 'components/BidhubFooter';
import ProjectCard from 'components/ProjectCard';
import TopBar from 'components/TopBar';

export default function Home() {
  const [homepageProjectsData, setHPD] = useState();

  const fetchProjectData = useCallback(() => {
    fetch('/api/projects/homepage')
      .then((r) => r.json())
      .then(setHPD);
  }, []);

  useEffect(() => fetchProjectData(), [fetchProjectData]);

  return (
    <>
      <div className="vh-full vw-full flex flex-col items-center justify-center">
        <TopBar className="bg-base-100" />
        <Image
          src="/homepage-assets/bidhub-banner.png"
          width={1440}
          height={810}
          alt="BidHub: Fair Pricing for All"
        />
        <div className="h-4 md:h-16" />

        <div className="flex flex-col md:flex-row items-center w-full max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg bg-base-100 md:space-x-8">
          {/* Video */}
          <div className="flex items-center justify-center">
            <video controls>
              <source
                src="https://q3ropqtlp13xrxqa.public.blob.vercel-storage.com/bidhub-900p.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
            <div className="flex flex-col space-y-4 items-center md:items-start p-4 ml-8">
              <h2 className="text-h2 md:text-h1 font-semibold text-center md:text-left tracking-wide">
                BidHub: The web3 Sales Solution
              </h2>
              <p className="font-light md:text-lg pl-2 md:p-0">
                BidHub helps sellers move product in bulk; from NFTs to merch, travel to events, and
                even cloud computing, BidHub can help you get the best price for your offerings.
              </p>
              <p className="font-light md:text-lg pl-2 md:p-0">
                The secret? Buyers get to name their own prices. With real-time on-chain refunds,
                there are no losers with BidHub.
              </p>
            </div>
          </div>
        </div>

        {/* Phases
        <div className="h-24" />
        <div className="flex flex-col items-center w-full max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
          <div className="carousel">
            <div className="carousel-item">
              <Image
                src="/homepage-assets/Public_Column@2x.png"
                alt="Public Phase"
                width={370}
                height={438}
              />
            </div>
            <div className="carousel-item">
              <Image
                src="/homepage-assets/Whitelist_Column@2x.png"
                alt="WL Phase"
                width={370}
                height={438}
              />
            </div>
            <div className="carousel-item">
              <Image
                src="/homepage-assets/Airdrop_Column@2x.png"
                alt="Airdrop Phase"
                width={370}
                height={438}
              />
            </div>
          </div>
        </div>
        */}
        {/* Schedule */}
        {homepageProjectsData?.length > 0 && (
          <>
            <div className="h-12" />
            <div className="flex flex-col items-center w-full max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg space-y-12">
              <div className="divider text-3xl md:text-5xl font-semibold">Launching on Bidhub</div>
              {homepageProjectsData.map((projectData) => (
                <ProjectCard
                  key={projectData.project_id}
                  projectData={projectData}
                  subTitle={
                    projectData.project_id === 'wolfcapital' &&
                    'Solana’s first NFT lending aggregator and analytics platform.'
                  }
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div className="h-20" />

      {/* Footer */}
      <BidhubFooter />
    </>
  );
}
