'use client';

import dynamic from 'next/dynamic';

/*
const WalletMultiButtonDynamic = dynamic(
  () => import('@solana/wallet-adapter-react-ui').then((mod) => mod.WalletMultiButton),
  { ssr: false }
);
*/
const WalletMultiButtonDynamic = dynamic(
  async () => (await import('@solana/wallet-adapter-react-ui')).WalletMultiButton,
  { ssr: false }
);

export default function WalletMultiButton({ children, ...props }) {
  return <WalletMultiButtonDynamic {...props}>{children}</WalletMultiButtonDynamic>;
}
